import React, { useState, useMemo } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { Descriptions, Table, Tooltip, Spin, Alert } from "antd";

import { Marker } from "../../components/Marker";
import { Poly } from "../../components/Polyline";
import { FIELD_TYPES, GOOGLE_MAPS_KEY, filterTypes } from "../../constants";
import { IGridColumn, ILocation } from "../../types";
import format, { formatNumber } from "../../utils/format";
import { MyMapComponent } from "../Trips/MyMapComponent";
import NoLocationEntryView from "./NoLocationEntryView";

const columns: IGridColumn[] = [
  {
    dataIndex: "timestamp",
    sorter: true,
    title: "Fecha",
    filterType: filterTypes.dateRange,
    render: (text: string) => (
        <Tooltip placement="topLeft" title={format(text, FIELD_TYPES.RELATIVE_TIME)}>
          {format(text, FIELD_TYPES.DATETIME)}
        </Tooltip>
    ),
  },
  {
    dataIndex: "ignition",
    sorter: true,
    title: "Motor",
    render: (text: string) => (text ? "Encendido" : "Apagado"),
  },
  {
    dataIndex: "battery",
    sorter: true,
    title: "Batería",
    render: (text: string) => `${formatNumber(text, 1)} V`,
  },
  {
    dataIndex: "speed",
    sorter: true,
    title: "Velocidad",
    align: "right",
    render: (text: string) => `${text} km/h`,
  },
  {
    dataIndex: "odometer",
    sorter: true,
    title: "Odometro",
    align: "right",
    render: (text: number) => `${formatNumber(text / 1000, 1)} km`,
  },
];

interface ResultsProps {
  data: {
    location_entry_aggregated: {
      speed_max: number;
      speed_avg: number;
      distance: number;
      record_count: number;
    };
    records: ILocation[];
  } | null;
  loading?: boolean;
  error?: string;
}

export function Results({ data, loading, error }: ResultsProps) {
  const [zoom, setZoom] = useState<number>(3);

  // Always call useMemo, even if data is null, to satisfy hook rules.
  const reversedRecords = useMemo(() => {
    return data && data.records && data.records.length > 0
        ? [...data.records].reverse()
        : [];
  }, [data]);

  if (loading) {
    return (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <Spin tip="Cargando..." />
        </div>
    );
  }

  if (error) {
    return (
        <div style={{ padding: "20px" }}>
          <Alert message="Error" description={error} type="error" showIcon />
        </div>
    );
  }

  if (!data) return null;

  const { location_entry_aggregated, records } = data;

  if (!records || records.length === 0) {
    return <NoLocationEntryView />;
  }

  const onIdle = (map: google.maps.Map) => {
    setZoom(map.getZoom()!);
  };

  return (
      <div>
        <Descriptions size="small" title="Datos agregados" className="p-2">
          <Descriptions.Item label="Velocidad máxima">
            {formatNumber(location_entry_aggregated.speed_max, 0)} km/h
          </Descriptions.Item>
          <Descriptions.Item label="Velocidad promedio">
            {formatNumber(location_entry_aggregated.speed_avg, 0)} km/h
          </Descriptions.Item>
          <Descriptions.Item label="Distancia">
            {formatNumber(location_entry_aggregated.distance / 1000, 1)} km
          </Descriptions.Item>
          <Descriptions.Item label="Registros">
            {formatNumber(location_entry_aggregated.record_count, 0)}
          </Descriptions.Item>
        </Descriptions>
        <div
            style={{
              height: "600px",
              width: "100%",
              border: "1px solid grey",
              marginBottom: 10,
            }}
            className="in-section"
        >
          <Wrapper apiKey={GOOGLE_MAPS_KEY}>
            <MyMapComponent locationEntries={records} onIdle={onIdle} style={{ height: "600px" }}>
              {records.map((location: ILocation) => (
                  <Marker position={location} key={location.id} zoom={zoom} />
              ))}
              <Poly path={reversedRecords} />
            </MyMapComponent>
          </Wrapper>
        </div>
        <Table
            className="table-striped-rows"
            columns={columns}
            dataSource={records}
            rowKey={(record) => record.id}
            size="small"
        />
      </div>
  );
}
