import React, { useState, useCallback } from "react";
import { Col, Row } from "antd";

import { OPERANDS } from "../../constants";
import { getURI } from "../../helpers/GetURI";
import { get } from "../../helpers/v2api";
import useForm from "../../hooks/useForm";
import { QueryState } from "../../types";
import { Query } from "./Query";
import { Results } from "./Results";

const ENDPOINT = "reports/location-entries";

export function LocationEntriesReport() {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fields = [
    { name: "vehicle_id", required: true },
    { name: "timestamp", required: true },
  ];

  const { formState, onChange, handleSubmit, errors } = useForm({}, fields);
  const { entity, submitted } = formState;

  const onSubmit = useCallback(async () => {
    setLoading(true);
    setError(null);
    const q: QueryState = {
      filters: {
        vehicle_id: {
          operand: OPERANDS.eq,
          value: entity["vehicle_id"],
        },
        timestamp: {
          operand: OPERANDS.range,
          value: entity["timestamp"],
        },
      },
      page: 1,
    };

    try {
      const uri = getURI(ENDPOINT, q);
      const axiosResponse = await get(uri);
      setData(axiosResponse.data);
    } catch (err) {
      console.error("Error fetching data:", err);
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred.");
      }
    } finally {
      setLoading(false);
    }
  }, [entity]);

  return (
      <Row gutter={16}>
        <Col span={8}>
          <Query
              errors={errors}
              submitted={submitted}
              entity={entity}
              onChange={onChange}
              onSubmit={handleSubmit(onSubmit)}
          />
        </Col>
        <Col span={16}>
          <Results data={data} loading={loading} error={error || undefined} />
        </Col>
      </Row>
  );
}
